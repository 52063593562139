// import './src/styles/global.css';

// scroll to top when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location?.pathname && prevLocation?.pathname && location?.pathname !== prevLocation?.pathname) {
    document.getElementById('___gatsby')?.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }
};
